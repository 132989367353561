import { FC, ReactEventHandler } from 'react';
import { useIntl } from 'react-intl';
import ViewHeadlineRoundedIcon from '@mui/icons-material/ViewHeadlineRounded';

import messages from './messages';

import useStyles from './styles';

type Props = {
  onClick: ReactEventHandler;
};

const NavbarButton: FC<Props> = ({ onClick }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <button
      className={classes.root}
      type="button"
      onClick={onClick}
      data-mozza="mobileBurger"
      aria-label={formatMessage(messages.ariaLabel)}
    >
      <ViewHeadlineRoundedIcon className={classes.icon} />
    </button>
  );
};

export default NavbarButton;
