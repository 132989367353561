import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const toggleRemoteScreenShow = keyframes`
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  },
`;

const toggleRemoteScreenHide = keyframes`
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  },
`;

export default makeStyles()((theme) => ({
  root: {
    overflowY: 'scroll',
  },
  section: {
    animationName: `${toggleRemoteScreenShow}`,
    animationDuration: '.4s',
    animationTimingFunction: 'ease-in-out',
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    width: '100%',
  },
  backdrop: {
    zIndex: 0,
  },
  show: {
    animationName: `${toggleRemoteScreenShow}`,
  },
  hide: {
    animationName: `${toggleRemoteScreenHide}`,
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    height: '100vh',
  },
  header: {
    padding: '16px 16px 12px',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    padding: '14px 16px',
    backgroundColor: theme.palette.neutral.light,
    borderRadius: '8px',
  },
  body: {
    padding: '16px',
    backgroundColor: theme.palette.common.white,
  },
}));
