import { FC, useState } from 'react';

import { HOME } from '@/data/navbar/navButtons';
import SidePanel from '@/Organisms/SidePanel/SidePanel';
import { HEADER_NAVIGATION_ID } from '@/shared/Navbar/constant';
import { useNavbarContext } from '@/shared/Navbar/context/NavbarContext';
import ItemNavigation from '@/shared/Navbar/Items/ItemNavigation/ItemNavigation';
import LinkSherpa from '@/shared/Navbar/Items/LinkSherpa/LinkSherpa';
import LoginButton from '@/shared/Navbar/Items/LoginButton/LoginButton';
import NavbarButton from '@/shared/Navbar/Items/NavbarButton/NavbarButton';
import NavbarSearch from '@/shared/Navbar/Items/NavbarSearch/NavbarSearch';
import useCommonStyles from '@/shared/Navbar/styles';

const NavbarGuestUserMobile: FC = () => {
  const { isLandingPage } = useNavbarContext();
  const { classes: commonClasses } = useCommonStyles({
    shouldAnimateNavBar: isLandingPage,
  });
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  const openSidePanel = () => {
    setIsSidePanelOpen(true);
  };

  const closeSidePanel = () => {
    setIsSidePanelOpen(false);
  };

  return (
    <>
      <header className={commonClasses.root} id={HEADER_NAVIGATION_ID}>
        <div className={commonClasses.navContainer}>
          <div className={commonClasses.navigationMenu}>
            <NavbarButton onClick={openSidePanel} />
            <LinkSherpa href={HOME} />
            <NavbarSearch />
          </div>

          <div className={commonClasses.itemNavigation}>
            <div className={commonClasses.connexionButton}>
              <LoginButton />
            </div>
          </div>
        </div>
      </header>

      {isSidePanelOpen && (
        <SidePanel
          isOpen={isSidePanelOpen}
          title={
            <LinkSherpa href={HOME} onClick={closeSidePanel} isSidePanelOpen />
          }
          onClose={closeSidePanel}
        >
          <div className={commonClasses.linkList}>
            <ItemNavigation onLinkClick={closeSidePanel} />
          </div>
        </SidePanel>
      )}
    </>
  );
};

export default NavbarGuestUserMobile;
