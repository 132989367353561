import {
  FC,
  PropsWithChildren,
  ReactElement,
  ReactEventHandler,
  useEffect,
  useState,
} from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Backdrop, IconButton, Modal } from '@mui/material';

import useStyles from './styles';

type Props = {
  isOpen: boolean;
  onClose: ReactEventHandler;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  title?: ReactElement;
};

const SidePanel: FC<PropsWithChildren<Props>> = ({
  title,
  isOpen,
  onClose,
  ariaLabelledBy,
  ariaDescribedBy,
  children,
}) => {
  const [isSidePanelFullyExited, setIsSidePanelFullyExited] = useState(!isOpen);
  const { classes } = useStyles();

  const onExitComplete = () => {
    setIsSidePanelFullyExited(true);
  };

  useEffect(() => {
    if (isOpen) {
      setIsSidePanelFullyExited(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setIsSidePanelFullyExited(false);
    }
  }, [isOpen]);

  return (
    <>
      {!isSidePanelFullyExited && (
        <Modal
          aria-labelledby={ariaLabelledBy}
          aria-describedby={ariaDescribedBy}
          className={classes.root}
          open={isOpen}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 400,
            className: classes.backdrop,
          }}
        >
          <section
            className={`${classes.section} ${
              isOpen ? classes.show : classes.hide
            }`}
            onAnimationEnd={!isOpen ? onExitComplete : null}
          >
            <div className={classes.paper}>
              <div className={classes.header}>
                {!!title && title}
                <IconButton
                  onClick={onClose}
                  classes={{ root: classes.button }}
                  size="large"
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </div>
              <div className={classes.body}>{children}</div>
            </div>
          </section>
        </Modal>
      )}
    </>
  );
};
export default SidePanel;
