import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, border, breakpoints }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(16),
    padding: rem(12),
    borderRadius: rem(8),
    backgroundColor: palette.common.white,
    border: border.main,
    cursor: 'pointer',
    maxWidth: rem(86),
    [breakpoints.up('md')]: {
      maxWidth: rem(205),
    },
    marginRight: rem(16),
  },
  icon: {
    color: palette.neutral[200],
    fill: palette.neutral[200],
  },
}));
